import { FC, useEffect, useMemo, useState } from 'react'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import Dialog from '@/components/dialog'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { cls } from '@/utils'
import Input from '@/components/input'
import { Textarea } from '@/components/ui/textarea'
import LoadingButton from '@/components/loading-button'
import { Checkbox } from '@/components/ui/checkbox'
import Button from '@/components/button'
import { createCollection, deleteCollection, updateCollection } from '@/service/collection.service'
import { Collection } from '@/types'
import { MEMBERSHIP_TIER_IDS } from '@/constants'
import useActivePlan from '@/hooks/useActivePlan'
import Link from '@/components/link'

interface IProps {
  open: boolean
  onOpenChange: (v: boolean) => void
  onCreateSuccess?: (key?: string) => void
  isEdit: boolean
  editingCollection?: Collection | null
  onDeleteCollection?: () => void
}

const formSchema = z.object({
  name: z.string().min(1, 'Required').default(''),
  description: z.string().optional().default(''),
  is_public: z.boolean().default(true),
})

const EditProjectDialog: FC<IProps> = (props) => {
  const { open, onOpenChange, isEdit, onCreateSuccess, editingCollection, onDeleteCollection } = props
  const [deleteProjectOpen, setDeleteProjectOpen] = useState(false)
  const { data: activePlan } = useActivePlan()
  const canGeneratePrivate = !!activePlan?.allow_private_generation

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      description: '',
      is_public: true,
    },
  })

  useEffect(() => {
    if (isEdit && editingCollection) {
      const { name, description, is_public } = editingCollection
      form.setValue('name', name)
      form.setValue('description', description || '')
      form.setValue('is_public', !!is_public)
    } else {
      form.resetField('name')
      form.resetField('description')
      form.resetField('is_public')
    }
  }, [isEdit, editingCollection, form])

  const handleSubmit = async () => {
    const values = form.getValues()
    const isValid = await form.trigger()
    if (!isValid) return
    try {
      if (isEdit) {
        await updateCollection({
          ...values,
          collection_id: editingCollection?.collection_id || '',
        })
        onCreateSuccess && onCreateSuccess()
      } else {
        const res = await createCollection(values)
        onCreateSuccess && onCreateSuccess(res.collection_id)
      }
    } catch (e) {
      return
    }
  }

  const inputClassName =
    'text-body-md rounded-md bg-surface h-10 w-full border-2 border-border shadow-xs hover:border-border-hover'

  const deleteProject = () => {
    setDeleteProjectOpen(true)
  }

  const confirmDelete = async () => {
    if (editingCollection?.collection_id) {
      try {
        await deleteCollection(editingCollection?.collection_id)
        setDeleteProjectOpen(false)
        onDeleteCollection && onDeleteCollection()
      } catch (e) {
        return
      }
    }
  }

  const handleOpenChange = (v: boolean) => {
    if (!v) {
      if (isEdit && editingCollection) {
        const { name, description, is_public } = editingCollection
        form.setValue('name', name)
        form.setValue('description', description || '')
        form.setValue('is_public', !!is_public)
      } else {
        form.resetField('name')
        form.resetField('description')
        form.resetField('is_public')
      }
    }
    onOpenChange(v)
  }

  const isSaveDisable = () => {
    const values = form.getValues()
    return !values.name
  }

  return (
    <>
      <Dialog
        title={isEdit ? 'Edit collection' : 'Create a new collection'}
        open={open}
        className='w-100 md:w-100'
        footer={null}
        onOpenChange={handleOpenChange}
      >
        <Form {...form}>
          <form
            className={cls('relative flex flex-col w-full items-center mt-4')}
            onSubmit={form.handleSubmit(handleSubmit)}
          >
            <div aria-label='form' className='flex flex-col gap-6 w-full'>
              <FormField
                control={form.control}
                name='name'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className='text-body-lg font-normal'>Name</FormLabel>
                    <FormControl>
                      <Input className={inputClassName} placeholder='' maxLength={30} {...field} />
                    </FormControl>
                    <div className='flex flex-row-reverse items-center w-full justify-between h-4'>
                      <FormDescription className='flex justify-end items-start m-0'>
                        {field.value.length}/30
                      </FormDescription>
                      <FormMessage className='h-4 m-0' />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='description'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className='text-body-lg font-normal'>
                      Description
                      <span className='text-text-subdued'> (optional)</span>
                    </FormLabel>
                    <FormControl>
                      <Textarea className={inputClassName} placeholder='' maxLength={150} {...field} />
                    </FormControl>
                    <div className='flex flex-row-reverse items-center w-full justify-between h-4'>
                      <FormDescription className='flex justify-end items-start m-0'>
                        {field.value.length}/150
                      </FormDescription>
                      <FormMessage className='h-4 m-0' />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='is_public'
                render={({ field }) => (
                  <FormItem className='space-y-0'>
                    <FormControl>
                      <div className='flex items-center justify-between'>
                        <div className='flex items-center gap-3'>
                          <div className='size-6 flex items-center justify-center'>
                            <Checkbox
                              checked={!field.value}
                              disabled={!canGeneratePrivate}
                              className='border-border border-2 box-content'
                              onCheckedChange={(v) => field.onChange(!v)}
                            />
                          </div>
                          <span className={cls('text-body-lg', canGeneratePrivate ? '' : 'opacity-50')}>Private</span>
                        </div>
                        <div>
                          <span>Only for </span>
                          <Link className='text-surface-primary cursor-pointer' href='/membership'>
                            Pro Plan
                          </Link>
                        </div>
                      </div>
                    </FormControl>
                    <div className='flex flex-row-reverse items-center w-full justify-between h-4 mt-0'>
                      <FormMessage className='h-4 m-0' />
                    </div>
                  </FormItem>
                )}
              />
            </div>
          </form>
        </Form>
        {isEdit ? (
          <>
            <div className='h-[1px] bg-border mt-2 mb-6 w-full'></div>
            <div className='text-text-subdued tracking-15 mb-1'>Danger zone</div>
            <div
              className='text-text-critical hover:text-text-critical-hover text-body-lg tracking-15 mb-1 cursor-pointer'
              onClick={deleteProject}
            >
              Delete collection
            </div>
          </>
        ) : null}
        <div className='pt-6 flex gap-3 w-full'>
          <Button variant='outline' className='flex-1' onClick={() => handleOpenChange(false)}>
            Cancel
          </Button>
          <LoadingButton
            variant='primary'
            className='flex-1 rounded-md'
            disabled={isSaveDisable()}
            onClick={handleSubmit}
          >
            {isEdit ? 'Save' : 'Create'}
          </LoadingButton>
        </div>
      </Dialog>
      <Dialog
        open={deleteProjectOpen}
        title='Delete project'
        footer={
          <div className='w-full flex gap-3 mt-3'>
            <Button variant='outline' className='flex-1 py-1.5' onClick={() => setDeleteProjectOpen(false)}>
              Cancel
            </Button>
            <Button
              variant='primary'
              className='flex-1 py-1.5 bg-surface-critical-strong hover:bg-surface-critical-strong-hover'
              onClick={confirmDelete}
            >
              Delete
            </Button>
          </div>
        }
        onOpenChange={(e) => setDeleteProjectOpen(e)}
      >
        <div className='text-text-subdued'>Are you sure to delete Cyberpunk?</div>
      </Dialog>
    </>
  )
}

export default EditProjectDialog
