import { useMemo } from 'react'
import useExpandedSubmissions, { useCachedExpandedSubmissions } from './useExpandedSubmissions'

const useEventSubmission = (submission_id: string) => {
  const ids = useMemo(() => (submission_id ? [submission_id] : []), [submission_id])
  const { data, ...others } = useExpandedSubmissions(ids)
  return { data: data?.[submission_id] ?? null, ...others }
}

export const useCachedEventSubmission = (submission_id: string) => {
  const ids = useMemo(() => (submission_id ? [submission_id] : []), [submission_id])
  const { data, ...others } = useCachedExpandedSubmissions(ids)
  return { data: data?.[submission_id] ?? null, ...others }
}

export default useEventSubmission
