import * as React from 'react'

import { cls } from '@/utils'

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    return (
      <textarea
        ref={ref}
        className={cls(
          'flex min-h-[80px] w-full rounded-md bg-surface px-3 py-2 text-body-md placeholder:text-text-subdued focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 focus:border-border-hover border-border hover:border-border-hover border-2 shadow-sm focus:shadow-border',
          className,
        )}
        {...props}
      />
    )
  },
)
Textarea.displayName = 'Textarea'

export { Textarea }
