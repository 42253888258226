import { DEFAULT_AVATAR } from '@/constants'
import { cls } from '@/utils'
import Image from '../image'

export interface AvatarProps {
  src: string
  alt?: string
  className?: string
}

export default function Avatar({ src = DEFAULT_AVATAR, alt = 'Avatar', className }: AvatarProps) {
  return (
    <Image
      className={cls('rounded-full object-cover size-6 shrink-0', className)}
      src={src || DEFAULT_AVATAR}
      alt={alt}
      onError={(e) => {
        const img = e.currentTarget
        if (img.src !== DEFAULT_AVATAR) {
          img.src = DEFAULT_AVATAR
        }
      }}
    />
  )
}
