import { copyText } from '@/utils'
import { useCallback, useMemo } from 'react'
import useAmplitude from './useAmplitude'

export interface useShareProps {
  link: string
  description?: string
  trackEventParams?: Record<string, any>
}

const useShare = ({
  link,
  trackEventParams,
  description = 'Check out this video created on @HaiperGenAI 👀 ',
}: useShareProps) => {
  const encodedLink = useMemo(() => encodeURIComponent(link), [link])
  const encodedDescription = useMemo(
    () => encodeURIComponent(description),
    [description],
  )

  const { track } = useAmplitude()

  const share = useCallback(async () => {
    try {
      await navigator.share?.({
        title: 'Haiper AI',
        text: description,
        url: link,
      })
    } catch (err) {
      console.error('Error sharing:', err)
    }
  }, [link, description])

  const shareToTwitter = useCallback(async () => {
    track('click:share:twitter', trackEventParams)
    const url = `https://twitter.com/intent/tweet?text=${encodedDescription}&url=${encodedLink}`
    window.open(url, '_blank')
  }, [encodedDescription, encodedLink, track, trackEventParams])

  const shareToInstagram = useCallback(async () => {
    track('click:share:instagram', trackEventParams)
    // Instagram doesn't support sharing from the web
    // https://stackoverflow.com/a/69311554/2380603
    const body = `${description} ${link}`
    await copyText(body)

    // open Instagram
    window.open('https://instagram.com', '_blank')
  }, [description, link, track, trackEventParams])

  const shareToWhatsapp = useCallback(async () => {
    track('click:share:whatsapp', trackEventParams)
    const url = `https://api.whatsapp.com/send?text=${encodedDescription} ${encodedLink}`
    window.open(url, '_blank')
  }, [encodedDescription, encodedLink, track, trackEventParams])

  const shareToFacebook = useCallback(async () => {
    track('click:share:facebook', trackEventParams)
    // quote no longer works, but we'll keep it for now. https://developers.facebook.com/support/bugs/734680371318112/
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodedLink}&quote=${encodedDescription}`

    window.open(url, '_blank')
  }, [encodedLink, encodedDescription, track, trackEventParams])

  const shareToMessenger = useCallback(async () => {
    track('click:share:messenger', trackEventParams)
    const url = `https://www.facebook.com/dialog/send?link=${encodedLink}`
    window.open(url, '_blank')
  }, [encodedLink, track, trackEventParams])

  return {
    share,
    shareToTwitter,
    shareToFacebook,
    shareToInstagram,
    shareToWhatsapp,
    shareToMessenger,
  }
}

export default useShare
