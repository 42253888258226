'use client'

import { PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { DialogCloseButtonClassName } from '@/components/ui/dialog'
import Dialog, { DialogProps } from '@/components/dialog'
import IconShare from '@haiper/icons-svg/icons/outline/share.svg'
import Button from '../button'
import IconLink from '@haiper/icons-svg/icons/outline/link.svg'
import IconCheck from '@haiper/icons-svg/icons/outline/check-large.svg'
import { copyText, cls } from '@/utils'
import IconX from '@/public/assets/x.svg'
import IconFacebook from '@/public/assets/facebook.svg'
import useShare from '@/hooks/useShare'

interface ShareDialogProps extends DialogProps {
  title?: string
  link: string
  trackEventParams: Record<string, any>
}

export default function ShareDialog({
  title = 'Share the video',
  link,
  trackEventParams,
  children,
  open,
  onOpenChange,
}: PropsWithChildren<ShareDialogProps>) {
  const { shareToTwitter, shareToFacebook } = useShare({
    link,
    trackEventParams,
  })

  const [copied, setCopied] = useState(false)

  const handleCopyLink = useCallback(async () => {
    await copyText(link ?? '')
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1500)
  }, [link])

  const shareButtonStyle =
    'size-10 rounded-full border border-border border-solid flex items-center justify-center'

  return (
    <Dialog
      open={open}
      trigger={children}
      title={
        <div className='flex flex-col gap-4'>
          <div
            aria-label='icon'
            className='size-12 rounded-full bg-surface-active p-3'
          >
            <IconShare className='size-full text-icon-interactive' />
          </div>
          <span className='text-heading-lg font-bold'>{title}</span>
        </div>
      }
      titleClassName='mb-6'
      className={cls('gap-0')}
      footerClassName='mt-[30px]'
      footer={null}
      onOpenAutoFocus={(e) => {
        e?.preventDefault()
        if (!e.target) return
        const elSelf = e.target as HTMLElement
        const elClose = elSelf.querySelector(
          `.${DialogCloseButtonClassName}`,
        ) as HTMLButtonElement
        elClose?.focus?.()
      }}
      onOpenChange={onOpenChange}
    >
      <div className='relative flex flex-col gap-5 w-full'>
        <div className='w-full flex justify-between h-18' aria-label='apps'>
          <div className='flex flex-1 justify-center items-center'>
            <Button
              variant='transparent'
              className='h-full'
              onClick={shareToTwitter}
            >
              <div className='flex flex-col gap-1 items-center justify-center w-18'>
                <div className={shareButtonStyle}>
                  <IconX className='size-[26px] text-surface-on-video dark:text-text' />
                </div>
                <span className='text-body-sm'>X</span>
              </div>
            </Button>
          </div>
          <div className='flex flex-1 justify-center items-center'>
            <Button
              variant='transparent'
              className='h-full'
              onClick={shareToFacebook}
            >
              <div className='flex flex-col gap-1 items-center justify-center w-18'>
                <div className={shareButtonStyle}>
                  <IconFacebook className='size-[26px] text-icon' />
                </div>
                <span className='text-body-sm'>Facebook</span>
              </div>
            </Button>
          </div>
        </div>
        <Button variant='outline' className='w-full' onClick={handleCopyLink}>
          <div className='flex gap-1 items-center'>
            <div className='size-6 p-[3px]'>
              {copied ? (
                <IconCheck className='size-full text-text-interactive' />
              ) : (
                <IconLink className='size-full text-icon' />
              )}
            </div>
            <span>Copy the link</span>
          </div>
        </Button>
      </div>
    </Dialog>
  )
}
