import { useState } from 'react'
import Button, { ButtonProps } from '@/components/button'
import { toast } from '@/components/ui/use-toast'
import IconCheck from '@haiper/icons-svg/icons/outline/check-large.svg'
import IconCopy from '@haiper/icons-svg/icons/outline/copy-3.svg'
import { cls, copyText } from '@/utils'

let timeoutId: NodeJS.Timeout

interface ButtonCopyProps extends ButtonProps {
  text?: string
  failedMessage?: string
  onCopied?: (text: string) => void
}

export default function ButtonCopy({
  text,
  failedMessage = 'Unable to copy, please try select and copy',
  className,
  onCopied,
  ...props
}: ButtonCopyProps) {
  const [copied, setCopied] = useState(false)
  return (
    <Button
      {...props}
      type='button'
      className={cls('h-5 grow-0 shrink-0 rounded-md', className)}
      onClick={() => {
        if (!text) return
        copyText(text)
          .then(() => {
            if (timeoutId) {
              clearTimeout(timeoutId)
            }
            setCopied(true)
            onCopied?.(text)
            timeoutId = setTimeout(() => {
              setCopied(false)
            }, 3000)
          })
          .catch(() => {
            toast({
              title: failedMessage,
            })
          })
      }}
    >
      {copied ? (
        <div className='size-5 shrink-0 grow-0 flex items-center justify-center'>
          <IconCheck className='text-surface-primary size-4 opacity-100 group-hover:opacity-100' />
        </div>
      ) : (
        <IconCopy className={cls('text-icon size-5', text ? '' : 'text-icon-disable')} />
      )}
    </Button>
  )
}
