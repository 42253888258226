import { cls } from '@/utils'
import { FC } from 'react'
import { cva, type VariantProps } from 'class-variance-authority'

const variants = cva('flex px-2 py-1 items-center justify-center rounded-md text-body-md tracking-15', {
  variants: {
    variant: {
      primary: 'bg-surface-active text-text-interactive',
      outline: '',
    },
    size: {
      md: 'h-7',
    },
  },
  defaultVariants: {
    size: 'md',
    variant: 'outline',
  },
})

export interface TagProps extends VariantProps<typeof variants> {
  className?: string
  icon?: FC<{ className?: string }>
  text: string
}

export default function Tag({ className, variant, size, icon: Icon, text }: TagProps) {
  return (
    <div className={cls(variants({ variant, size }), className)}>
      {Icon && <Icon className={cls('size-4 shrink-0 text-icon', variant === 'primary' && 'text-icon-interactive')} />}
      <span className='px-1'>{text}</span>
    </div>
  )
}
