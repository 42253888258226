import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'

import { cls } from '@/utils'

const badgeVariants = cva(
  cls(
    'inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  ),
  {
    variants: {
      variant: {
        default: cls('border-transparent bg-primary text-text-interactive'),
        secondary: cls('border-transparent bg-surface-subdued text-text-subdued'),
        destructive: cls('border-transparent bg-destructive text-text-critical'),
        outline: cls('text-text'),
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return <div className={cls(badgeVariants({ variant }), className)} {...props} />
}

export { Badge, badgeVariants }
