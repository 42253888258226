import { likeCacheAtom } from '@/atoms'
import { useAtom } from 'jotai'
import { useCallback, useEffect, useMemo } from 'react'

const useLikeCache = (id: string = '', autoClear?: boolean) => {
  const [likeCache, setLikeCache] = useAtom(likeCacheAtom)

  const toggle = useCallback(
    (liked?: boolean) => {
      if (!id) {
        return
      }

      setLikeCache((prev) => {
        const newState = { ...prev, [id]: liked ?? !prev[id] }
        return newState
      })
    },
    [setLikeCache, id],
  )

  const liked = useMemo(() => (id ? likeCache?.[id] ?? undefined : undefined), [likeCache, id])

  const clearAll = useCallback(
    (prefix?: string) => {
      if (!prefix) {
        setLikeCache({})
      } else {
        setLikeCache((prev) => {
          const newState = Object.keys(prev).reduce(
            (acc, key) => {
              if (!key.startsWith(prefix)) {
                acc[key] = prev[key]
              }
              return acc
            },
            {} as Record<string, boolean>,
          )
          return newState
        })
      }
    },
    [setLikeCache],
  )

  const clear = useCallback(() => {
    if (!id) {
      return
    }
    setLikeCache((prev) => {
      const newState = { ...prev }
      delete newState[id]
      return newState
    })
  }, [setLikeCache, id])

  useEffect(() => {
    if (autoClear) {
      return clear
    }
  }, [clear, autoClear])

  return { likeCache, toggle, liked, clearAll, clear }
}

export const withPrefix = (id: string, prefix: string) => {
  return id ? `${prefix}${id}` : id
}

export const CreationOutputLikeCachePrefix = 'creation-output:'
export const CommentLikeCachePrefix = 'comment:'
export const SpotlightLikeCachePrefix = 'spotlight:'
export const TemplateLikeCachePrefix = 'template:'

export const useCreationOutputLikeCache = (id: string, autoClear?: boolean) =>
  useLikeCache(withPrefix(id, CreationOutputLikeCachePrefix), autoClear)

export const useCommentLikeCache = (id: string, autoClear?: boolean) =>
  useLikeCache(withPrefix(id, CommentLikeCachePrefix), autoClear)

export const useSpotlightLikeCache = (id: string, autoClear?: boolean) =>
  useLikeCache(withPrefix(id, SpotlightLikeCachePrefix), autoClear)

export const useTemplateLikeCache = (id: string, autoClear?: boolean) =>
  useLikeCache(withPrefix(id, TemplateLikeCachePrefix), autoClear)

export default useLikeCache
