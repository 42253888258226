import { OutputSpec } from '@/types'
import { cancelableGetVideoSpec } from '@/utils'
import { useEffect, useState } from 'react'

const url2SpecMap: Record<string, OutputSpec> = {}

const useVideoSpec = (url: string, allowCache = false): { data: OutputSpec | undefined; loading: boolean } => {
  const lastSpec = allowCache ? url2SpecMap[url] : undefined
  const shouldFetch = !lastSpec || !allowCache
  const [loading, setLoading] = useState(shouldFetch)
  const [spec, setSpec] = useState(lastSpec)

  useEffect(() => {
    const { promise, abort } = cancelableGetVideoSpec(shouldFetch ? url : '')
    if (shouldFetch) {
      setLoading(true)
      promise
        ?.then((duration) => {
          url2SpecMap[url] = duration
          setSpec(duration)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    }

    return () => {
      abort()
    }
  }, [shouldFetch, url])

  return {
    data: spec,
    loading,
  }
}

export default useVideoSpec

export const useCachedVideoSpec = (url: string) => {
  return useVideoSpec(url, true)
}
