import { cls, stopPropagation, whisper } from '@/utils'
import {
  useEffect,
  useState,
  useCallback,
  useMemo,
  PropsWithChildren,
} from 'react'
import Dialog, { DialogProps } from '@/components/dialog'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import IconLock from '@haiper/icons-svg/icons/outline/lock.svg'
import IconUnLock from '@haiper/icons-svg/icons/outline/unlocked.svg'
import { toast } from '@/components/ui/use-toast'
import { putPublicStateOfCreationById } from '@/service/job.service'
import { PoNVoid } from '@/types'
import useAmplitude from '@/hooks/useAmplitude'
import useActivePlan from '@/hooks/useActivePlan'
import Link from '@/components/link'

export interface VisibilityDialogProps extends DialogProps {
  creationId: string
  isPublic: boolean
  onOk?: (isPublic: boolean) => PoNVoid
  onCancel?: () => PoNVoid
}

export default function VisibilityDialog({
  open,
  children,
  creationId,
  isPublic,
  onOk,
  onCancel,
  onOpenChange,
}: PropsWithChildren<VisibilityDialogProps>) {
  const [pendingPublic, setPendingPublic] = useState<boolean>(isPublic)
  const { track } = useAmplitude()
  const { data: myPlan } = useActivePlan()

  useEffect(() => {
    setPendingPublic(isPublic)
  }, [isPublic])

  const handleTogglePublic = useCallback(
    async (isPublic: boolean) => {
      const eventId = isPublic
        ? 'click:creation:visibility-public'
        : 'click:creation:visibility-private'
      track(eventId, { creation_id: creationId })
      try {
        await putPublicStateOfCreationById(creationId, isPublic)
        toast({
          title: 'Visibility updated',
          color: 'success',
        })
        setPendingPublic(isPublic)
      } catch (error) {
        toast({
          title: 'Failed to update visibility',
        })
      }
      await onOk?.(isPublic)
    },
    [creationId, onOk, track],
  )

  const options = useMemo(() => {
    return [
      {
        label: 'Public',
        value: 'true',
        description: 'Everyone can view',
        Icon: IconUnLock,
      },
      {
        label: 'Private',
        value: 'false',
        description: 'Only I can view',
        note: myPlan?.allow_private_generation ? null : (
          <span className='text-text-subdued text-body-md tracking-15 font-normal'>
            Only for
            <Link
              href='/membership'
              className='text-text-interactive hover:text-text-interactive-hover font-bold hover:no-underline pl-1'
              onClick={stopPropagation as any}
            >
              Pro Plan
            </Link>
          </span>
        ),
        Icon: IconLock,
      },
    ]
  }, [myPlan])

  return (
    <Dialog
      open={open}
      trigger={children}
      title='Who can view'
      okText='Confirm'
      cancelText='Cancel'
      onOk={() => handleTogglePublic(pendingPublic)}
      onCancel={() => {
        setPendingPublic(isPublic)
        onCancel?.()
      }}
      onOpenChange={onOpenChange}
    >
      <RadioGroup
        value={String(pendingPublic)}
        className='py-4'
        onValueChange={(v) => setPendingPublic(v !== 'false')}
      >
        {options.map(({ value, label, description, Icon, note }) => {
          const disabled =
            !myPlan?.allow_private_generation && value === 'false'

          const id = `${creationId}-visibility-${value}`
          return (
            <div
              key={[label, value].join()}
              className={cls(
                'flex items-center w-full justify-between min-h-14 rounded-md border border-solid border-border py-2 px-5 cursor-pointer hover:bg-surface-hover',
                disabled ? 'cursor-not-allowed' : '',
              )}
              onClick={() => {
                setPendingPublic(value !== 'false')
              }}
            >
              <label
                htmlFor={id}
                className={cls(
                  'flex gap-5 text-text flex-1 items-center cursor-pointer',
                  disabled ? 'cursor-not-allowed' : '',
                )}
              >
                <Icon
                  className={cls('size-6 stroke-1', disabled && 'opacity-50')}
                />
                <div className='flex-1 flex flex-col'>
                  <div
                    className={cls(
                      'text-base font-bold leading-normal flex gap-3 items-center',
                      disabled && 'opacity-50',
                    )}
                  >
                    <span>{label}</span>
                  </div>
                  <div
                    className={cls(
                      'text-body-md text-text leading-normal',
                      disabled && 'opacity-50',
                    )}
                  >
                    {description}
                  </div>
                </div>
              </label>
              {disabled ? (
                note
              ) : (
                <RadioGroupItem value={value} disabled={disabled} id={id} />
              )}
            </div>
          )
        })}
      </RadioGroup>
    </Dialog>
  )
}
