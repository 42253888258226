import { useRef, useState } from 'react'
import FileSaver from 'file-saver'

interface DownloadFileProps {
  name: string | null | undefined
  url?: string
}

const useDownloadFile = ({ url, name }: DownloadFileProps) => {
  const [downloading, setDownloading] = useState(false)
  const downloadingRef = useRef(false)

  async function handleDownload(realUrl?: string) {
    const parsedUrl = realUrl ?? url
    if (!parsedUrl) return
    if (downloadingRef.current) return

    downloadingRef.current = true
    setDownloading(true)
    const realName = name ?? 'video.mp4'
    try {
      if (parsedUrl) {
        FileSaver.saveAs(parsedUrl, realName)
      } else {
        // not possible
      }

      setDownloading(false)
      downloadingRef.current = false
    } catch (error) {
      setDownloading(false)
      downloadingRef.current = false
      throw error
    }
  }

  return {
    download: handleDownload,
    downloading,
  }
}

export default useDownloadFile
