import useVideoSpec from './useVideoSpec'

const useVideoDuration = (url: string, allowCache = false): { data: number | undefined; loading: boolean } => {
  const { data: spec, loading } = useVideoSpec(url, allowCache)

  return {
    data: spec?.duration,
    loading,
  }
}

export const useCachedVideoDuration = (url: string) => {
  return useVideoDuration(url, true)
}
