import { FC, ReactNode } from 'react'
import { cls } from '@/utils'
import IconEmpty from '@/public/assets/empty.svg'

export interface EmptyProps {
  className?: string
  icon?: FC<any>
  description?: string | ReactNode
  iconClassName?: string
  textClassName?: string
}

export default function Empty({ className, description = 'No Data', icon, iconClassName, textClassName }: EmptyProps) {
  const Icon = icon ?? IconEmpty

  return (
    <div className={cls('flex flex-col justify-between items-center gap-6', className)}>
      <div className={cls('p-0.5')}>
        <Icon className={cls('size-16', iconClassName)} />
      </div>
      <div className={cls('text-heading-lg font-bold', textClassName)}>{description}</div>
    </div>
  )
}
