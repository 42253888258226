import useMarketingEvents, { useCachedMarketingEvents } from './useMarketingEvents'

const paginationQuery = {
  page: 1,
  limit: 9999,
}

const useMarketingEvent = (event_id: string) => {
  const { data: events, ...others } = useMarketingEvents(paginationQuery)
  const event = events?.records?.find((event) => event.event_id === event_id) ?? null
  return { data: event, ...others }
}

export const useCachedMarketingEvent = (event_id: string) => {
  const { data: events, ...others } = useCachedMarketingEvents(paginationQuery)
  const event = events?.records?.find((event) => event.event_id === event_id) ?? null
  return { data: event, ...others }
}

export default useMarketingEvent
