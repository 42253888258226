import IconDots from '@haiper/icons-svg/icons/outline/dot-hor.svg'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { cls, stopPropagation } from '@/utils'
import Button, { ButtonProps } from '@/components/button'
import { ComponentProps, FC, ReactElement, ReactNode, isValidElement, useCallback, useMemo, useState } from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import Tooltip from '@/components/tooltip'
import { TooltipContentProps } from '@radix-ui/react-tooltip'

const buttonGroupVariants = cva('border border-border hover:border-border-hover rounded-full flex items-center', {
  variants: {
    size: {
      md: 'h-10',
      sm: 'h-8',
      lg: 'h-12',
    },
    variant: {
      outline: '',
      transparent: 'border-none border-0 divide-x-0 rounded-none',
    },
  },
  defaultVariants: {
    size: 'md',
    variant: 'outline',
  },
})

const buttonGroupItemVariants = cva('max-h-full border-0 border-l min-w-max px-[6px]', {
  variants: {
    size: {
      md: 'h-10',
      sm: 'h-8',
      lg: 'h-12',
    },
    variant: {
      outline:
        'first-of-type:rounded-l-full first-of-type:rounded-s-full last-of-type:rounded-r-full first-of-type:pl-1 last-of-type:pr-1 rounded-none',
      transparent: 'rounded-md border-0 border-none',
    },
  },
  defaultVariants: {
    size: 'md',
    variant: 'outline',
  },
})

export interface ButtonGroupItem
  extends Pick<ButtonProps, 'disabled' | 'onClick' | 'className'>,
    VariantProps<typeof buttonGroupItemVariants> {
  key: string
  icon?: FC<ComponentProps<'div'>>
  label?: string
  iconOnly?: boolean
  folded?: boolean
  tooltip?:
    | string
    | ReactElement
    | {
        title?: string | ReactElement
        content: string | ReactElement
        className?: string
        duration?: number
        align?: TooltipContentProps['align']
        closeOnClick?: boolean
        delayDuration?: number
      }
  iconClassname?: string
  visible?: boolean
}

export interface ButtonGroupProps extends VariantProps<typeof buttonGroupVariants> {
  className?: string
  itemClassName?: string
  iconClassname?: string
  iconWrapClassname?: string
  items: ButtonGroupItem[]
}

export default function ButtonGroup({
  className,
  itemClassName,
  iconClassname,
  iconWrapClassname,
  items,
  size,
  variant,
}: ButtonGroupProps) {
  const visibleItems = useMemo(() => {
    return items.filter((item) => item.visible)
  }, [items])

  const foldedItems = useMemo(() => {
    return visibleItems.filter((item) => item.folded)
  }, [visibleItems])

  const unfoldedItems = useMemo(() => {
    return visibleItems.filter((item) => !item.folded)
  }, [visibleItems])

  const showMoreButton = foldedItems.length > 0
  const [dropDownVisible, setDropDownVisible] = useState(false)

  const foldedButtonStyle = cls(
    'w-full h-10 rounded-md p-0 border-0 bg-surface hover:bg-surface-hover active:bg-surface-hover',
  )

  const hideMoreButtonDropdown = useCallback(() => {
    setDropDownVisible(false)
  }, [])

  const withTooltip = (
    icon: JSX.Element,
    tooltip:
      | ReactElement
      | string
      | {
          title?: string | ReactElement
          content: string | ReactElement
          className?: string
          closeOnClick?: boolean
          delayDuration?: number
          align?: TooltipContentProps['align']
        },
    key?: string,
  ): ReactNode => {
    const isReactElement = isValidElement(tooltip)
    const isString = typeof tooltip === 'string'
    const className: string | undefined = isReactElement || isString ? undefined : (tooltip as any)?.className
    const closeOnClick: boolean = isReactElement || isString ? undefined : (tooltip as any)?.closeOnClick
    const align = isReactElement || isString ? undefined : (tooltip as any)?.align

    const delayDuration = isReactElement || isString ? undefined : (tooltip as any)?.delayDuration

    return (
      <Tooltip
        key={key}
        // asChild
        triggerClassName={cls(
          'pointer-events-auto',
          buttonGroupItemVariants({ size, variant }),
          itemClassName,
          iconWrapClassname,
          'items-center flex justify-center',
        )}
        className={cls('pointer-events-auto', className)}
        trigger={icon}
        align={align}
        delayDuration={delayDuration}
        closeOnClick={closeOnClick ?? true}
        onOpenChange={hideMoreButtonDropdown}
      >
        <div>
          {isReactElement ? (
            tooltip
          ) : (
            <div className='flex flex-col gap-1 text-text-on-color'>
              {(tooltip as any).title ? (
                <span className='text-body-md tracking-15'>{(tooltip as any).title}</span>
              ) : null}
              {(tooltip as any).content ? (
                <span className='text-body-md whitespace-normal'>{(tooltip as any).content}</span>
              ) : null}
            </div>
          )}
        </div>
      </Tooltip>
    )
  }

  if (!visibleItems?.length) {
    return null
  }

  return (
    <div
      aria-label='button-group'
      className={cls(buttonGroupVariants({ size, variant }), className)}
      onClick={stopPropagation as any}
    >
      {unfoldedItems.map(
        ({
          disabled,
          className,
          tooltip = '',
          icon: Icon,
          iconClassname: itemIconClassname,
          onClick,
          iconOnly,
          label,
          key,
        }) => {
          const parsedClassName = cls('text-icon', iconClassname, itemIconClassname)
          return withTooltip(
            <Button
              key={key}
              variant={variant}
              disabled={disabled}
              className='p-0 h-auto bg-transparent pointer-events-auto'
              data-testid={key}
              onClick={onClick}
            >
              <div className='flex items-center gap-1 size-full'>
                <div className='size-full h-full flex items-center justify-center'>
                  {Icon ? <Icon aria-label={key} className={parsedClassName} /> : null}
                </div>
                {iconOnly || !label ? null : <span className={className}>{label}</span>}
              </div>
            </Button>,
            tooltip,
            key,
          )
        },
      )}
      {showMoreButton ? (
        <Popover open={dropDownVisible} onOpenChange={setDropDownVisible}>
          <PopoverTrigger asChild>
            <Button
              className={cls(
                buttonGroupItemVariants({ size, variant }),
                itemClassName,
                iconWrapClassname,
                'text-text opacity-100 dark:text-text dark:opacity-100',
              )}
            >
              <IconDots
                aria-label='edit'
                className={cls(iconClassname, 'text-icon opacity-100 dark:text-icon dark:opacity-100')}
              />
            </Button>
          </PopoverTrigger>
          <PopoverContent
            className='p-1 w-[150px] rounded-[10px] border border-border border-solid'
            align='end'
            side='top'
          >
            {foldedItems.map(({ key, disabled, onClick, label }) => {
              return (
                <Button
                  key={key}
                  variant='outline'
                  disabled={disabled}
                  className={cls(foldedButtonStyle)}
                  onClick={onClick}
                >
                  <div>{label}</div>
                </Button>
              )
            })}
          </PopoverContent>
        </Popover>
      ) : null}
    </div>
  )
}
