import dayjs from 'dayjs'
import { cls, formatAspectRatio, getSizeByResolutionAndAspectRatio, openNewTab, whisper } from '@/utils'
import IconArrowTopRight from '@haiper/icons-svg/icons/outline/arrow-top-right.svg'
import IconCupChampionWin from '@haiper/icons-svg/icons/solid/cup-champion-win.svg'
import { Creation, CreationOutput, GenerationConfig, Size, TemplateGenerationParams } from '@/types'
import { Badge } from '@/components/ui/badge'
import ButtonCopy from '../copy-button'
import useAmplitude from '@/hooks/useAmplitude'
import { useCallback, useMemo } from 'react'
import { useCachedEventSubmission } from '@/hooks/useEventSubmission'
import useMarketingEvent from '@/hooks/useMarketingEvent'
import Button from '../button'
import Tag from '../tag'
import { EVENT_ID_CREATION_SHOWCASE, EVENT_ID_SPOTLIGHT, SOCIAL_ENTRIES } from '@/constants'

interface CreationInfoProps
  extends Pick<
      Creation,
      'prompt' | 'creation_id' | 'create_time' | 'type' | 'input_type' | 'spec' | 'settings' | 'model_version'
    >,
    Pick<CreationOutput, 'submission_id'> {
  className?: string
  promptClassName?: string
  metaClassName?: string
  createTimeClassName?: string
  config: Creation['config'] | TemplateGenerationParams['config']
}

// export to test it
export const getPromptPrefix = ({
  type,
  config,
  input_type,
  prompt,
}: Pick<CreationInfoProps, 'type' | 'config' | 'input_type' | 'prompt'>): string => {
  if (input_type === 'text' && !prompt) {
    return ''
  }
  const delimiter = ' + '

  let content = ''
  if (type === 'template' && (config as TemplateGenerationParams['config'])?.template_id) {
    // calculate file counts by config.template_inputs
    const nonEmptyKeys = Object.keys((config as TemplateGenerationParams['config'])?.template_inputs ?? {}).filter(
      (key) => (config as TemplateGenerationParams['config']).template_inputs?.[key],
    )
    const videoCount = nonEmptyKeys.filter((key) => key.includes('video') && key.includes('url')).length
    const imageCount = nonEmptyKeys.filter((key) => key.includes('image') && key.includes('url')).length

    content = [
      videoCount && `${videoCount} video${videoCount > 1 ? 's' : ''}`,
      imageCount && `${imageCount} image${imageCount > 1 ? 's' : ''}`,
    ]
      .filter(Boolean)
      .join(delimiter)
  } else {
    const fileCount = Math.max(
      1,
      Number(
        input_type === 'image'
          ? (config as GenerationConfig)?.source_images?.filter(Boolean)?.length ?? 1
          : input_type === 'video'
            ? 1
            : 0,
      ) || 0,
    )

    if (input_type === 'image') {
      content = `${fileCount} image${fileCount > 1 ? 's' : ''}`
    } else if (input_type === 'video') {
      content = '1 video'
    }
  }
  const showDelimiter = !!content && !!prompt
  return content + (showDelimiter ? delimiter : '')
}

export default function CreationInfo({
  className,
  promptClassName,
  metaClassName,
  createTimeClassName,
  input_type,
  prompt,
  settings,
  spec,
  creation_id,
  config,
  create_time,
  type,
  model_version,
  submission_id,
}: CreationInfoProps) {
  const { track } = useAmplitude()

  const { data: submission } = useCachedEventSubmission(submission_id ?? '')
  const showEvent =
    submission?.event_id && ![EVENT_ID_CREATION_SHOWCASE, EVENT_ID_SPOTLIGHT].includes(submission?.event_id)

  const { data: marketingEvent } = useMarketingEvent(showEvent ? submission?.event_id ?? '' : '')
  const socialPost = submission?.social_post ?? null
  const platform = useMemo(() => {
    return SOCIAL_ENTRIES.find((entry) => entry.name === socialPost?.platform) ?? null
  }, [socialPost])

  const SocialPlatformIcon = platform?.Icon ?? null
  const SocialPlatformName = platform?.label ?? null

  const eventName = useMemo(() => {
    return [marketingEvent?.abstract, marketingEvent?.title]
      .map((item) => item?.trim())
      .filter(Boolean)
      .join(' - ')
  }, [marketingEvent])

  const tagClassName = 'rounded-sm text-text-subdued px-2 py-1 text-body-sm bg-surface-base hover:bg-surface-base/90'

  const prefix = useMemo(() => {
    return getPromptPrefix({
      type,
      config,
      input_type,
      prompt,
    })
  }, [type, config, input_type, prompt])

  const outputSize: Size | null = useMemo(() => {
    if (spec?.width && spec?.height) {
      return { width: spec.width, height: spec.height }
    }
    if (settings?.resolution && settings?.aspect_ratio) {
      return getSizeByResolutionAndAspectRatio({
        resolution: settings.resolution,
        aspectRatio: settings.aspect_ratio,
      })
    } else if (settings?.width && settings?.height) {
      return { width: settings.width, height: settings.height }
    }
    return null
  }, [settings, spec])

  const cameraMovement = (config as GenerationConfig)?.camera_movement
  const gotoSocialPost = useCallback(() => {
    if (socialPost?.url) {
      openNewTab(socialPost.url)
    }
  }, [socialPost])

  return (
    <div className={cls('flex flex-col min-h-0 gap-4', className)} aria-label='creation info'>
      {socialPost && SocialPlatformIcon && SocialPlatformName && (
        <Button variant='outline' className='w-full h-7 p-1 shadow-sm' onClick={gotoSocialPost}>
          <div className='w-full flex items-center'>
            <SocialPlatformIcon className='size-5 text-icon shrink-0' />
            <span className='px-1 flex-1 text-left text-body-md tracking-15'>{SocialPlatformName}</span>
            <IconArrowTopRight className='size-5 text-icon-subdued shrink-0' />
          </div>
        </Button>
      )}
      <div className={cls('flex-1 min-h-0 gap-2.5 flex', promptClassName)}>
        <div className='w-5 h-5'>
          <ButtonCopy
            className='p-0 size-5 text-icon-subdued'
            text={prompt}
            onCopied={(seed) => {
              track('click:creation:copy-prompt', { creation_id: creation_id })
            }}
          />
        </div>
        <div className='h-max flex-1 no-scrollbar text-text'>
          {!!prefix && <span className='text-text-interactive shrink-0'>{prefix}</span>}
          {/* {!!prefix && !!prompt && <span className='text-text shrink-0'>&nbsp;+&nbsp;</span>} */}
          {prompt ? <span className='break-all'>{prompt}</span> : null}
        </div>
      </div>
      {marketingEvent && eventName && showEvent && (
        <Tag variant='primary' className='px-2 py-1 w-max' icon={IconCupChampionWin} text={eventName} />
      )}
      <div className={cls('flex flex-row flex-wrap gap-2 shrink-0 mt-auto', metaClassName)}>
        {creation_id ? (
          <Badge className={tagClassName}>
            id
            <span className='ml-2 break-all md:break-normal'>{creation_id}</span>
          </Badge>
        ) : null}
        {model_version ? (
          <Badge className={tagClassName}>
            model version
            {/* <span className='ml-2'>haiper {model_version}</span> */}
            <span className='ml-2'>haiper {model_version.replace('2.0', '2.*')}</span>
          </Badge>
        ) : null}
        {settings?.seed ? (
          <Badge className={tagClassName}>
            seed
            <span className='ml-2'>{settings?.seed}</span>
          </Badge>
        ) : null}
        {settings?.aspect_ratio ? (
          <Badge className={tagClassName}>
            ar
            <span className='ml-2'>{formatAspectRatio(settings?.aspect_ratio ?? '--')}</span>
          </Badge>
        ) : null}
        {settings?.motion_level ? (
          <Badge className={tagClassName}>
            motion level
            <span className='ml-2'>{settings?.motion_level === -1 ? 'default' : settings?.motion_level}</span>
          </Badge>
        ) : null}
        {outputSize ? (
          <Badge className={tagClassName}>
            resolution
            <span className='ml-2'>
              {outputSize.width}x{outputSize.height}
            </span>
          </Badge>
        ) : null}
        {cameraMovement ? (
          <Badge className={tagClassName}>
            camera control
            <span className='ml-2'>{cameraMovement.replace(/_/g, ' ')}</span>
          </Badge>
        ) : null}
      </div>
      {create_time ? (
        <div className={cls('text-body-sm text-text-subdued', createTimeClassName)}>
          {dayjs(create_time).format('YYYY-MM-DD HH:mm')}
        </div>
      ) : null}
    </div>
  )
}
