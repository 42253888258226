import { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import IconPlus from '@haiper/icons-svg/icons/outline/plus-small.svg'
import IconFolder from '@haiper/icons-svg/icons/outline/folder.svg'
import Dialog from '@/components/dialog'
import Button from '@/components/button'
import { cls } from '@/utils'
import { Checkbox } from '@/components/ui/checkbox'
import EditProjectDialog from '@/components/edit-collection-dialog'
import LoadingButton from '../loading-button'
import Loading from '../loading'
import Image from '../image'
import { useCachedCollections } from '@/hooks/useCollections'
import { Collection } from '@/types'

interface SelectCollectionDialogProps {
  selectedKeys: string[]
  creationId: string
  loading?: boolean
  onKeysChange: (keys: string[]) => void
  onAddToCollection: (keys: string[]) => void
}

export interface SelectCollectionDialogRef {
  open: () => Promise<void>
  close: () => void
}

const SelectCollectionDialog = forwardRef(
  ({ selectedKeys, onAddToCollection, onKeysChange, loading }: SelectCollectionDialogProps, ref) => {
    const [selectProjectOpen, setSelectProjectOpen] = useState(false)
    const [addProjectOpen, setAddProjectOpen] = useState(false)
    const { data: collectionList, refresh: refreshCollections, wait: waitCollections } = useCachedCollections()

    const open = useCallback(async () => {
      const res = await waitCollections()
      if (res?.length) {
        setSelectProjectOpen(true)
      } else {
        setAddProjectOpen(true)
      }
    }, [waitCollections])

    const close = useCallback(() => {
      setSelectProjectOpen(false)
      setAddProjectOpen(false)
    }, [])

    useImperativeHandle(
      ref,
      () => ({
        open,
        close,
      }),
      [open, close],
    )

    const handleClick = useCallback(
      (index: string) => {
        const findIndex = selectedKeys.indexOf(index)
        if (findIndex >= 0) {
          selectedKeys.splice(findIndex, 1)
          onKeysChange([...selectedKeys])
        } else {
          onKeysChange([...selectedKeys, index])
        }
      },
      [selectedKeys, onKeysChange],
    )

    const createSuccess = useCallback(
      async (key?: string) => {
        refreshCollections()
        if (key) {
          onKeysChange([...selectedKeys, key])
        }

        setSelectProjectOpen(true)
        setAddProjectOpen(false)
      },
      [refreshCollections, onKeysChange, selectedKeys],
    )

    const handleOpenChange = useCallback((open: boolean) => {
      setSelectProjectOpen(false)
    }, [])

    return (
      <>
        <Dialog
          open={selectProjectOpen}
          title='Select collection'
          footer={
            <div className='flex gap-3 w-full'>
              <Button variant='outline' disabled={loading} className='flex-1' onClick={() => setAddProjectOpen(true)}>
                <IconPlus className='text-icon' />
                <span className='px-1 font-medium'>Create new collection</span>
              </Button>
              <LoadingButton
                variant='primary'
                disabled={loading}
                className='flex-1'
                onClick={async () => await onAddToCollection(selectedKeys)}
              >
                Save
              </LoadingButton>
            </div>
          }
          onOpenChange={handleOpenChange}
        >
          <div className='max-h-[476px] flex flex-col gap-2 mt-6 mb-8 overflow-y-auto'>
            {collectionList?.map((item: Collection) => (
              <div
                key={item.collection_id}
                className={cls(
                  'flex gap-2 border border-border rounded-md p-2 cursor-pointer relative h-[58px] flex-shrink-0',
                  selectedKeys.includes(item.collection_id) ? 'border-[2px] border-border-active p-[7px]' : '',
                  loading ? 'opacity-50 pointer-events-none cursor-default' : '',
                )}
                onClick={() => handleClick(item.collection_id)}
              >
                {item.preview_urls?.[0] ? (
                  <Image className='size-10 rounded-sm object-cover' src={item.preview_urls?.[0] || ''} alt='' />
                ) : (
                  <div className='size-10 rounded-sm flex justify-center items-center bg-surface-subdued'>
                    <IconFolder className='size-6 text-icon-subdued' />
                  </div>
                )}
                <div>
                  <div className='text-heading-sm font-bold tracking-15'>{item.name}</div>
                  <div>
                    <span className='mr-1 text-body-sm'>
                      {item?.number || 0} creation
                      {(item?.number || 0) > 1 ? 's' : ''}
                    </span>
                  </div>
                </div>
                <div className={cls('absolute size-4 right-4 top-[50%] translate-y-[-50%]')}>
                  <Checkbox checked={selectedKeys.includes(item.collection_id)} />
                </div>
              </div>
            ))}
            {loading && (
              <div className='absolute inset-0 min-h-12 flex items-center justify-center'>
                <Loading className='size-6 my-4' />
              </div>
            )}
          </div>
        </Dialog>
        <EditProjectDialog
          isEdit={false}
          open={addProjectOpen}
          editingCollection={null}
          onOpenChange={setAddProjectOpen}
          onCreateSuccess={createSuccess}
        />
      </>
    )
  },
)

SelectCollectionDialog.displayName = 'SelectCollectionDialog'

export default SelectCollectionDialog
